import React, { useContext, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useAuthHeaders } from "../../auth/AuthHeaders";
import ConfigContext from "../../store/context/ConfigContext";
import CreditResponse from "../response/CreditResponse";
import DomesticCreditForm from "./DomesticCreditForm";
import * as Yup from "yup";
import { Formik } from "formik";
import useSubmitCredit from "../../store/hooks/useSubmitCredit";
import { getCurrentFormattedDate } from "../../store/dateFormatter";

export const handleCreditResponse = (response, setInsufficientFundsError, setResponseModalOpen) => {
  if (!response) return;

  if (response.error?.code === "InsufficientFunds") {
    setInsufficientFundsError(true);
    setResponseModalOpen(false);
    return;
  }

  setInsufficientFundsError(false);
  setResponseModalOpen(true);
};

const CreateUSDCredit = ({
                           handleBack,
                           entityDetails,
                           transactionType
                         }) => {
  const config = useContext(ConfigContext);
  const authHeaders = useAuthHeaders();

  const responseRef = useRef(null);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [insufficientFundsError, setInsufficientFundsError] = useState(false);

  const {
    handleSubmit: handleCreditSubmit,
    response: creditResponse
  } = useSubmitCredit(authHeaders, config, "Domestic");

  useEffect(() => {
    handleCreditResponse(creditResponse, setInsufficientFundsError, setResponseModalOpen);
  }, [creditResponse]);

  const clearCreditResponse = () => {
    setResponseModalOpen(false);
  };

  return (
    <Box mt={2}>
      <Formik
        initialValues={{
          reference: `${entityDetails.label} - ${getCurrentFormattedDate()}`,
          account: "",
          amount: "",
          currency: "USD",
          entityId: entityDetails.value,
          when: "standard",
          description: "",
          totp: "",
          ledgerId: ""
        }}
        validationSchema={Yup.object().shape({
          reference: Yup.string().required("Internal Tracking Reference is required"),
          account: Yup.string()
            .nullable()
            .notRequired(),
          amount: Yup.number().required("Amount is required"),
          currency: Yup.string().required("Currency is required"),
          ledgerId: Yup.string().required("Source Account is required"),
          entityId: Yup.string().required("Entity Reference is required"),
          when: Yup.string().required("Delivery option is required"),
          description: Yup.string()
            .required("Purpose is required")
            .max(10, "Description must be a maximum of 10 characters")
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await handleCreditSubmit(values, { setSubmitting });
          setSubmitting(false);
        }}
      >
        {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
            validateForm,
            setTouched,
            setFieldValue
          }) => (
          <DomesticCreditForm
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            validateForm={validateForm}
            setTouched={setTouched}
            handleSubmit={handleSubmit}
            entityDetails={entityDetails}
            setFieldValue={setFieldValue}
            insufficientFundsError={insufficientFundsError}
            confirmationModalOpen={responseModalOpen}
            setConfirmationModalOpen={setResponseModalOpen}
            handleBack={handleBack}
            transactionType={transactionType}
          />
        )}
      </Formik>

      {creditResponse && (
        <Box mt={2} ref={responseRef}>
          <CreditResponse
            response={creditResponse}
            open={responseModalOpen}
            clearResponse={clearCreditResponse}
          />
        </Box>
      )}
    </Box>
  );
};

export default CreateUSDCredit;
