import { useTheme } from "@mui/material/styles";
import { AuthContext } from "./AuthContext";
import React, { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularSpinner from "../ui-component/CircularSpinner";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import AnimateButton from "../ui-component/extended/AnimateButton";
import { Formik } from "formik";
import ErrorToast from "../ui-component/alerts/ErrorToast";
import * as Yup from "yup";
import ConfigContext from "../store/context/ConfigContext";
import OtpInput from "react18-input-otp";
import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useClientAccount } from "../store/context/ClientAccountContext";

dayjs.extend(utc);

const PasswordLessLogin = ({ redirectTo = "/", ...others }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setIsAuthenticated, setAuthToken } = useContext(AuthContext);
  const config = useContext(ConfigContext);
  const [loading, setLoading] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [isEmailLocked, setIsEmailLocked] = useState(true);
  const [showTOTP, setShowTOTP] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const { updateClient } = useClientAccount();

  const handleUnlockEmail = () => {
    setIsEmailLocked(false);
  };

  const handleEditEmail = () => {
    setIsEmailLocked(true);
  };

  const validateEmail = (email) => {
    if (!email) return "Email is required";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return "Invalid email";
    return "";
  };

  const handleEmailInputKeyPress = (e) => {
    if (e.key === "Enter" && !emailError) {
      handleNextStep();
    }
  };

  const handleNextStep = () => {
    const error = validateEmail(email);
    setEmailError(error);
    if (!error) {
      setShowTOTP(true);
    }
  };

  const handleAuthentication = (values, formikHelpers) => {
    setAttemptedSubmit(true);
    setLoading(true);
    axios
      .post(`${config.API_URL}/login/v1/login`, {
        u: email,
        c: values.totp,
        d: `${config.LOGIN_DOMAIN_UUID}`,
      })
      .then((response) => {
        if (response.status === 200 && response.data.jwt) {
          const { jwt, realms, domain, tos } = response.data;
          setAuthToken(jwt);
          setIsAuthenticated(true);

          const currentTime = dayjs();
          const restrictions = domain.meta.restrict
            ? domain.meta.restrict
            : null;

          if (
            restrictions &&
            restrictions["payment::create"] &&
            restrictions["payment::create"].opens_at &&
            restrictions["payment::create"].closes_at &&
            restrictions["payment::create"].timezone &&
            Object.keys(restrictions["payment::create"]).length > 0
          ) {
            const openTime = restrictions["payment::create"].opens_at;
            const closeTime = restrictions["payment::create"].closes_at;
            const timeZone = restrictions["payment::create"].timezone;

            const openTimestamp = currentTime
              .add(restrictions["payment::create"].opens_in, "second")
              .utc()
              .local()
              .format();
            const closeTimestamp = currentTime
              .add(restrictions["payment::create"].closes_in, "second")
              .utc()
              .local()
              .format();

            sessionStorage.setItem(
              "openTimestamp",
              JSON.stringify(openTimestamp),
            );
            sessionStorage.setItem(
              "closeTimestamp",
              JSON.stringify(closeTimestamp),
            );
            sessionStorage.setItem("bankOpensAt", openTime);
            sessionStorage.setItem("bankClosesAt", closeTime);
            sessionStorage.setItem("timezone", timeZone);
          }

          const currencies = domain.meta.currencies || [
            "CAD",
            "USD",
            "EUR",
            "AUD",
            "GBP",
          ];
          sessionStorage.setItem("currencies", JSON.stringify(currencies));

          if (domain.meta.rateDisclaimer) {
            sessionStorage.setItem(
              "rateDisclaimer",
              domain.meta.rateDisclaimer,
            );
          }

          if (domain.meta.flags.displayRejectReason) {
            sessionStorage.setItem(
              "displayRejectReason",
              domain.meta.flags.displayRejectReason = "true",
            );
          }

          if (domain.meta.paymentDescriptions) {
            sessionStorage.setItem(
              "paymentDescriptions",
              domain.meta.paymentDescriptions,
            );
          }

          if (domain.meta.flags.displayRateExpiry) {
            sessionStorage.setItem(
              "displayRateExpiry",
              domain.meta.flags.displayRateExpiry = "true",
            );
          }

          if (tos) {
            sessionStorage.setItem("tosAccepted", tos.accepted);
            sessionStorage.setItem("tosUrl", tos.url);
          }

          if (domain.meta.blocked && domain.meta.blocked.countries) {
            sessionStorage.setItem(
              "blockedCountries",
              JSON.stringify(domain.meta.blocked.countries),
            );
          }
          
          sessionStorage.setItem("realms", JSON.stringify(realms));
          sessionStorage.setItem("pdfTemplate", config.PDF_TEMPLATE);

          // Check if redirectTo contains a clientId and adjust it
          let finalRedirectTo = redirectTo;
          const match = redirectTo.match(/\/approvals\/([^/]+)\/(.*)/);
          if (match) {
            const clientId = match[1];
            sessionStorage.setItem("clientId", clientId);
            updateClient(clientId);
            finalRedirectTo = `/approvals/${match[2]}`;
          }

          navigate(finalRedirectTo, {
            state: {
              tos: tos,
            },
            replace: true,
          });
        } else {
          formikHelpers.setSubmitting(false);
          return Promise.reject(
            new Error(response.data.message || "Authentication failed"),
          );
        }
      })
      .catch((err) => {
        console.error(err);
        setShowErrorToast(true);
        formikHelpers.setSubmitting(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleErrorToastClose = () => {
    setShowErrorToast(false);
  };

  return (
    <>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Welcome, please enter your email.
      </Typography>
      {loading && <CircularSpinner />}
      <FormControl
        fullWidth
        error={Boolean(emailError)}
        sx={{ ...theme.typography.customInput }}
      >
        <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
        <OutlinedInput
          id="outlined-adornment-email"
          type="email"
          value={email}
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          onKeyDown={handleEmailInputKeyPress}
          disabled={showTOTP && isEmailLocked}
          endAdornment={
            showTOTP ? (
              <>
                {isEmailLocked ? (
                  <IconButton
                    edge="end"
                    onClick={handleUnlockEmail}
                    aria-label="unlock email"
                  >
                    <LockOpenOutlined />
                  </IconButton>
                ) : (
                  <IconButton
                    edge="end"
                    onClick={handleEditEmail}
                    aria-label="edit email"
                  >
                    <LockOutlined />
                  </IconButton>
                )}
              </>
            ) : null
          }
        />
        {emailError && (
          <FormHelperText error id="standard-weight-helper-text-email">
            {emailError}
          </FormHelperText>
        )}
      </FormControl>
      <Box sx={{ mt: 2 }}>
        {!showTOTP ? (
          <AnimateButton>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={handleNextStep}
            >
              Next
            </Button>
          </AnimateButton>
        ) : (
          <Formik
            initialValues={{ totp: "" }}
            validationSchema={Yup.object({
              totp: Yup.string()
                .length(6, "TOTP code must be 6 digits")
                .required("TOTP code is required"),
            })}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values, formikHelpers) =>
              handleAuthentication(values, formikHelpers)
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...others}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.totp && errors.totp)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Enter 6-digit authentication code{" "}
                  </Typography>
                  <OtpInput
                    value={values.totp}
                    onChange={(totpNumber) => {
                      handleChange({
                        target: { name: "totp", value: totpNumber },
                      });
                    }}
                    numInputs={6}
                    containerStyle={{ justifyContent: "space-between" }}
                    inputStyle={{
                      width: "100%",
                      margin: "8px",
                      padding: "10px",
                      border: `1px solid `,
                      borderRadius: 4,
                      ":hover": {
                        borderColor: "#000",
                      },
                    }}
                    focusStyle={{
                      outline: "none",
                      border: `2px solid #000`,
                    }}
                    onBlur={handleBlur}
                    shouldAutoFocus={true}
                  />
                  {attemptedSubmit && touched.totp && errors.totp && (
                    <FormHelperText error>{errors.totp}</FormHelperText>
                  )}
                </FormControl>
                <Box sx={{ mt: 2 }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      Sign in
                    </Button>
                  </AnimateButton>
                </Box>
              </form>
            )}
          </Formik>
        )}
      </Box>
      <ErrorToast
        open={showErrorToast}
        onClose={handleErrorToastClose}
        message={"Authentication failed, please try again"}
      />
    </>
  );
};

export default PasswordLessLogin;
