export const getLedgerDisplayText = (ledger) => {
  const { description, accounts, ledgerId, currency } = ledger;

  // Helper function to mask accounts for ledger display
  const maskAccount = (account) => {
    const accountStr = account.toString();
    if (accountStr.length > 4) {
      return `XXXX${accountStr.slice(-4)}`;
    }
    return accountStr; // Return the whole account if it's 4 or fewer characters
  };

  const flattenAccounts = (arr) => arr.flat(Infinity);
  const maskedAccounts = accounts
    ? flattenAccounts(accounts).map(maskAccount)
    : [];

  if (description) {
    return maskedAccounts?.length > 0
      ? `${description}: ${maskedAccounts.join(", ")}`
      : description;
  }

  const prefix = currency ? `${currency}: ` : "";
  return maskedAccounts?.length > 0
    ? `${prefix}${maskedAccounts.join(", ")}`
    : `${prefix}${ledgerId}`;
};
