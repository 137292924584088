// Project imports
import { formatDateToMonthDayYearWritten } from "common/getDate";
import getFormattedCurrencyAmount from "store/getFormattedCurrencyAmount";
import ensureNotNullAll from "store/pdf-generator/ensureNotNullAll";

// Assets
import pdfTemplateMozrt from "../../../assets/pdf/mozrt-wire-confirmation-template.pdf";
import pdfTemplateTriad from "../../../assets/pdf/tbb-wire-confirmation-template.pdf";
import pdfTemplateChiMoney from "../../../assets/pdf/chimoney-wire-confirmation-template.pdf";
import pdfTemplateFfb from "../../../assets/pdf/ffb-wire-confirmation-template.pdf";
import { maskAccountNumber } from "./visab2bTemplateOptionsBankTransaction";
import { formatAddress } from "./formatAddress";



const formatWireAddress = (addressLines) => {
  if (!addressLines || !Array.isArray(addressLines)) {
    return "N/A"; // Fallback for missing or invalid address
  }

  return addressLines.filter((line) => line && line.trim() !== "").join("\n");
};

// ============================|| ACH - PDF TEMPLATE OPTIONS ||============================ //

const wireTemplateOptions = (transactionData, templateName) => {
  const isoDateString = transactionData.createdAt;
  const beneficiaryDetails = transactionData.receiver.details.wire;
  const originatorDetails = transactionData.originator.details;

  const paymentDetails = ensureNotNullAll({
    transactionId: transactionData.id,
    formattedDate: formatDateToMonthDayYearWritten(isoDateString),
    beneficiaryName: transactionData.receiver.details.name,
    currency: transactionData.currency,
    formattedAmount: getFormattedCurrencyAmount(
      transactionData.amount,
      transactionData.currency
    ),
    purpose: transactionData.description,
    comments: "",
    addenda: transactionData.addenda,
    debit_account: maskAccountNumber(transactionData?.originator?.accountNumber),
  });

  const beneficiaryBankInfo = ensureNotNullAll({
    name: beneficiaryDetails?.bank?.name,
    swift: beneficiaryDetails?.bank?.swift,
    iban: beneficiaryDetails?.iban,
    account: beneficiaryDetails?.account,
    bankAddress: formatWireAddress(beneficiaryDetails?.bank?.address),
  });

  const senderInfo = ensureNotNullAll({
    name: originatorDetails?.name,
    address: originatorDetails?.address?.street1,
    city: originatorDetails?.address?.city,
    country: originatorDetails?.address?.country,
    regionCode: originatorDetails?.address?.regionCode,
    postalCode: originatorDetails?.address?.postalCode,
  });

  let selectedPdfTemplate;

  if (!templateName) {
    templateName = "Triad Business Bank";
    console.log("Missing Realm Name in Login Response");
  }

  switch (templateName) {
    case "Mozrt":
      selectedPdfTemplate = pdfTemplateMozrt;
      break;
    case "FFB":
      selectedPdfTemplate = pdfTemplateFfb;
      break;
    case "Triad Business Bank":
    case "TBB Dev":
      selectedPdfTemplate = pdfTemplateTriad;
      break;
    case "Chimoney":
      selectedPdfTemplate = pdfTemplateChiMoney;
      break;
    default:
      console.log("default case", templateName);
      selectedPdfTemplate = pdfTemplateTriad;
      break;
  }

  const templateData = [
    { fieldName: "date", fieldValue: paymentDetails.formattedDate },
    { fieldName: "transaction_id", fieldValue: paymentDetails.transactionId },
    { fieldName: "amount", fieldValue: paymentDetails.formattedAmount },
    {
      fieldName: "beneficiary_name",
      fieldValue: paymentDetails.beneficiaryName,
    },
    {
      fieldName: "beneficiary_account",
      fieldValue: beneficiaryBankInfo.account || beneficiaryBankInfo.iban,
    },
    {
      fieldName: "beneficiary_bank_name",
      fieldValue: beneficiaryBankInfo.name,
    },
    {
      fieldName: "beneficiary_bank",
      fieldValue: beneficiaryBankInfo.bankAddress,
    },

    { fieldName: "by_order_of", fieldValue: senderInfo.name },
    { fieldName: "by_order_of_address", fieldValue: formatAddress(senderInfo) },
    { fieldName: "debit_account", fieldValue: paymentDetails.debit_account },
    { fieldName: "purpose", fieldValue: paymentDetails.purpose },
    { fieldName: "addenda", fieldValue: paymentDetails.addenda },
  ];

  return {
    templateData,
    pdfTemplate: selectedPdfTemplate,
    fileNamePrefix: "Wire-Transaction-id-",
  };
};

export default wireTemplateOptions;


