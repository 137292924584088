import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
// material-ui
import {
  DataGrid,
  GridFooter,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

// project imports
import TransactionSummary from "../ui-component/TransactionSummary";
import { RefreshQueryToolbar } from "../ui-component/datagrid/RefreshQueryToolbar";
import ConfigContext from "../store/context/ConfigContext";
import { getTwoDaysLaterDateInYearMonthDay } from "../common/getDate";
import { transactionHistoryColumns } from "../ui-component/datagrid/transactionHistoryColumns";
import NonFormikLedgerDropDown from "../ui-component/ledger/NonFormikLedgerDropDown";
import { useClientAccount } from "../store/context/ClientAccountContext";
import { useAuthHeaders } from "../auth/AuthHeaders";
// third-party
import axios from "axios";
import { CustomNoRowsOverlay } from "../ui-component/datagrid/CustomEmptyDataTable";

// ============================|| Material UI TableGrid Style  ||============================ //
export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-toolbarContainer": {
    marginBottom: "16px",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    color: "#FFF",
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: ".75rem",
    },
  },
  "& .MuiDataGrid-sortIcon": {
    color: "#FFF", // white color
  },
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root": {
    color: "#FFF",
  },
  "& .MuiDataGrid-footerContainer": {
    minHeight: "80px",
    padding: 0,
    overflow: "hidden",
  },
  "& .MuiTablePagination-root": {
    overflow: "hidden",
    padding: 0,
  },
  "& .MuiTablePagination-toolbar": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  "& .MuiTablePagination-spacer": {
    display: "none",
  },

}));

// ============================|| Transaction History Page  ||============================ //
const Transactions = ({
  compactView = false,
  activeLedgers,
  ledgerLoading,
  error,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLedgerId, setSelectedLedgerId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({}); // For storing the column visibility model

  const authHeaders = useAuthHeaders();
  const config = useContext(ConfigContext);
  const { clientId } = useClientAccount();

  // Load saved column visibility preferences from localStorage when the component mounts
  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem("columnVisibilityModel");
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  // Save column visibility preferences to localStorage whenever they change
  useEffect(() => {
    if (Object.keys(columnVisibilityModel).length > 0) {
      localStorage.setItem(
        "columnVisibilityModel",
        JSON.stringify(columnVisibilityModel),
      );
    }
  }, [columnVisibilityModel]);

  useEffect(() => {
    setLoading(true);

    async function fetchTransactionHistory() {
      try {
        const response = await axios.post(
          `${config.API_URL}/figaro/v1/history`,
          {
            clientId: clientId,
            request: {
              begin: "2023-10-18",
              end: getTwoDaysLaterDateInYearMonthDay(),
              ledgerId: selectedLedgerId,
            },
          },
          {
            headers: authHeaders,
          },
        );

        const processedTransactions = (
          response.data.ledger?.transactions || []
        ).map((tx, index) => {
          let extractedData = extractTransactionData(tx);
          extractedData.id = index;
          return extractedData;
        });
        setData(processedTransactions.reverse());
      } catch (error) {
        if (error.response && error.response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/token-expiry";
        } else {
          console.error("An error occurred:", error);
        }
      } finally {
        setLoading(false);
      }
    }

    fetchTransactionHistory();
  }, [refreshFlag, clientId, selectedLedgerId]);

  const extractTransactionData = (transaction) => {
    let type = Object.keys(transaction)[0];
    let data = transaction[type];

    let id, reference, estimatedAt, state, amount, account, currency, createdAt;

    switch (type) {
      case "debit":
      case "credit":
      case "transfer":
      case "collected":
      case "received":
        estimatedAt = data.estimatedAt;
        reference = data.reference;
        state = data.state;
        id = data.id;
        createdAt = data.created;
        account = data.account;

        if (data.fx && data.fx.amount && data.fx.currency) {
          amount = data.fx.amount;
          currency = data.fx.currency;
        } else {
          amount = data.amount;
          currency = data.currency;
        }
        break;

      default:
        return null;
    }

    return {
      type,
      reference,
      state,
      estimatedAt,
      amount,
      currency,
      id,
      createdAt,
      account,
    };
  };

  const handleRowClickForTransactionDetailModal = async (reference) => {
    try {
      const response = await axios.post(
        `${config.API_URL}/figaro/v1/payment`,
        {
          clientId: clientId,
          request: {
            creditReference: reference,
          },
        },
        {
          headers: authHeaders,
        },
      );

      if (response.data.error) {
        console.error(
          "Error fetching transaction details:",
          response.data.error.message,
        );
        toast.error("Unable to fetch transaction details", {
          position: toast.POSITION.BOTTOM_LEFT,
          theme: "colored",
        });
      } else {
        setSelectedTransaction(response.data);
        setModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching transaction details:", error);
    }
  };

  const handleRefresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  // Update the column visibility model whenever the user changes column visibility
  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <div
      style={{
        height: compactView ? "400px" : "75vh",
        width: "100%",
        marginTop: "2vh",
      }}
    >
      <Grid container spacing={2} mb={2}>
        {!compactView && (
          <>
            <Grid item xs={8}>
              <Typography variant={"h2"} sx={{ mb: 3 }}>
                Transaction History
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <NonFormikLedgerDropDown
                ledgerData={activeLedgers}
                loading={ledgerLoading}
                error={error}
                selectedLedgerId={selectedLedgerId}
                setSelectedLedgerId={setSelectedLedgerId}
              />
            </Grid>
          </>
        )}
      </Grid>
      <StyledDataGrid
        rows={data}
        columns={transactionHistoryColumns}
        onRowClick={(param) => {
          handleRowClickForTransactionDetailModal(param.row.reference);
        }}
        columnVisibilityModel={columnVisibilityModel} // Use the visibility model from state
        onColumnVisibilityModelChange={handleColumnVisibilityChange} // Save visibility changes
        slots={{
          toolbar: compactView
            ? null
            : () => (
              <RefreshQueryToolbar onRefresh={handleRefresh} data={data} />
            ),
          noRowsOverlay: CustomNoRowsOverlay,
          footer: compactView
            ? () => (
              <GridFooterContainer mr={5} p={2}>
                <Grid item xs={4}>
                  <NonFormikLedgerDropDown
                    ledgerData={activeLedgers}
                    loading={ledgerLoading}
                    error={error}
                    selectedLedgerId={selectedLedgerId}
                    setSelectedLedgerId={setSelectedLedgerId}
                  />
                </Grid>
                <Grid item xs={8} textAlign="right">
                  <GridPagination />
                </Grid>
              </GridFooterContainer>
            )
            : () => (
              <GridFooterContainer>
                <GridPagination />
              </GridFooterContainer>
            ),
        }}
        sx={{
          boxShadow: 5,
          border: 2,
          borderColor: "primary.light",
          backgroundColor: "#fff",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        loading={loading}
        pageSizeOptions={compactView ? [5] : [5, 10, 25]}
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibilityModel || { type: false }, // Use saved visibility model if exists
          },
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
          pagination: {
            paginationModel: {
              pageSize: compactView ? 5 : 25,
            },
          },
        }}
      />
      <Dialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogContent>
          <TransactionSummary
            selectedTransaction={selectedTransaction}
            refetchTransactions={handleRefresh}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModalOpen(false)}
            color="secondary"
            variant={"contained"}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Transactions;
