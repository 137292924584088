import axios from "axios";
import { useState } from "react";
import getFormattedCurrencyAmount from "../getFormattedCurrencyAmount";

const useSubmitCredit = (authHeaders, config, paymentType, clientId) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [response, setResponse] = useState(null);

  // Retrieve clientId from sessionStorage if not provided - this is for regular transactions
  const getSessionStorageClientId = () => {
    return sessionStorage.getItem("clientId");
  };

  // Use the provided clientId or retrieve it from sessionStorage - this is for bank transactions to support them choosing who
  const getCurrentClientId = () => {
    return clientId || getSessionStorageClientId();
  };

  const handleTransactionSubmit = async (values, { setSubmitting }) => {
    const clientId = getCurrentClientId();

    const formattedAmount = parseFloat(
      getFormattedCurrencyAmount(values.amount, values.currency).replace(
        /[^0-9.-]+/g,
        "",
      ),
    );

    const requestData = {
      clientId: clientId,
      request: {
        reference: values.reference,
        ledgerId: values.ledgerId,
        ...(values.account && { account: values.account }),
        amount: formattedAmount,
        currency: values.currency,
        entityId: values.entityId,
        when: values.when,
        text: {
          description:
            values.description === "Other"
              ? values.descriptionOther
              : values.description, // Use free text when "other" is selected
          ...(values.addenda && { addenda: values.addenda }),
        },
        transactionNote: values.transactionNote,
        ...(values.purposeCode && { purposeCode: values.purposeCode }),
      },
      totp: values.totp,
    };

    if (values.account) {
      requestData.request.account = values.account;
    }
    if (paymentType) {
      requestData.request.fxQuoteId = values.fxQuoteId;
      requestData.request.quoteCurrency = values.quoteCurrency;
    }

    try {
      const options = {
        method: "POST",
        url: `${config.API_URL}/figaro/v1/payment`,
        headers: authHeaders,
        data: requestData,
      };

      const response = await axios.request(options);
      setResponse(response.data);
      setIsSubmitted(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = "/token-expiry";
      }
      console.error("Error:", error);
    } finally {
      if (setSubmitting) {
        setSubmitting(false);
      }
    }
  };

  return {
    handleSubmit: handleTransactionSubmit,
    isSubmitted,
    response,
  };
};

export default useSubmitCredit;
