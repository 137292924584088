import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { getLedgerDisplayText } from "../../store/getLedgerDisplay";

const NonFormikLedgerDropDown = ({
                                   ledgerData = [],
                                   loading,
                                   error,
                                   selectedLedgerId,
                                   setSelectedLedgerId,
                                 }) => {
  useEffect(() => {
    if (!selectedLedgerId && ledgerData && ledgerData.length > 0) {
      const usdLedger = ledgerData.find((ledger) => ledger.currency === "USD");
      const defaultLedger = ledgerData.find((ledger) => ledger.default === true);

      if (ledgerData.length === 1) {
        setSelectedLedgerId(ledgerData[0].ledgerId);
      } else if (usdLedger) {
        setSelectedLedgerId(usdLedger.ledgerId);
      } else if (defaultLedger) {
        setSelectedLedgerId(defaultLedger.ledgerId);
      }
    }
  }, [ledgerData, selectedLedgerId, setSelectedLedgerId]);

  const handleLedgerChange = (event) => {
    setSelectedLedgerId(event.target.value);
  };

  if (loading) return <CircularProgress />;

  if (error) {
    return <Typography variant="body1">Error: {error.message}</Typography>;
  }

  // Handle single ledger case
  if (ledgerData.length === 1 && !selectedLedgerId) {
    setSelectedLedgerId(ledgerData[0].ledgerId);
    return null;
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="ledger-label">Select Source Account</InputLabel>
      <Select
        labelId="ledger-label"
        id="ledger-select"
        value={selectedLedgerId || ""}
        onChange={handleLedgerChange}
        label="Select Source Account"
      >
        {ledgerData.map((ledger) => {
          const displayText = getLedgerDisplayText(ledger);
          return (
            <MenuItem key={ledger.ledgerId} value={ledger.ledgerId}>
              {displayText}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

NonFormikLedgerDropDown.propTypes = {
  ledgerData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  selectedLedgerId: PropTypes.string,
  setSelectedLedgerId: PropTypes.func.isRequired,
};

export default NonFormikLedgerDropDown;
