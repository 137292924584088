export const getCurrentFormattedDate = () => {
  const today = new Date();
  return `${String(today.getMonth() + 1).padStart(2, "0")}-${String(
    today.getDate()
  ).padStart(
    2,
    "0"
  )}-${today.getFullYear()} -${String(today.getHours()).padStart(2, "0")}:${String(
    today.getMinutes()
  ).padStart(2, "0")}`;
};