import React, { forwardRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Box, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { getDecimalPlaces, shouldFixDecimalScale } from "../../common/currencyDecimalFormater";

interface SelectOption {
  value: string;
  icon: string;
  label: string;
}

interface FormattedCurrencyFieldProps {
  name: string;
  value: string | number;
  placeholder?: string;
  onValueChange: (values: any) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  currency: string;
  selectOptions: SelectOption[];
  selectedOption: string;
  onSelectChange: (event: any, child?: React.ReactNode) => void;
  selectDisabled?: boolean;
  selectStyle?: React.CSSProperties;
  textFieldStyle?: React.CSSProperties;
  userHasGetQuoteRole: boolean;
  defaultOption: SelectOption;
}

const FormattedCurrencyField = forwardRef<HTMLInputElement, FormattedCurrencyFieldProps>(
  (
    {
      name,
      value,
      placeholder,
      onValueChange,
      onBlur,
      onKeyDown,
      onFocus,
      disabled = false,
      currency,
      selectOptions,
      selectedOption,
      onSelectChange,
      selectDisabled = false,
      selectStyle,
      textFieldStyle,
      userHasGetQuoteRole,
      defaultOption,
    },
    ref
  ) => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
      <NumericFormat
        fullWidth
        variant="outlined"
        name={name}
        value={value}
        placeholder={placeholder}
        onValueChange={onValueChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        thousandSeparator
        decimalScale={getDecimalPlaces(currency)}
        fixedDecimalScale={shouldFixDecimalScale(currency)}
        customInput={TextField}
        inputRef={ref}
        sx={{
          ...textFieldStyle,
          "& .MuiInputBase-input::placeholder": {
            fontSize: "1.2rem",
            opacity: 0.8,
          },
        }}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Select
                name={name}
                value={selectedOption}
                onChange={onSelectChange}
                displayEmpty
                sx={selectStyle}
                disabled={selectDisabled}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
                renderValue={(selected) => {
                  const option = selectOptions.find((opt) => opt.value === selected);
                  return (
                    <Box display="flex" alignItems="center">
                      {option?.icon && (
                        <img
                          src={option.icon}
                          alt={option.label}
                          style={{ marginRight: "10px", height: "30px" }}
                        />
                      )}
                      {option?.value}
                    </Box>
                  );
                }}
              >
                {userHasGetQuoteRole ? (
                  selectOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Box display="flex" alignItems="center">
                        {option.icon && (
                          <img
                            src={option.icon}
                            alt={option.label}
                            style={{ marginRight: "10px", height: "30px" }}
                          />
                        )}
                        {menuOpen && option.label
                          ? `${option.value} - ${option.label}`
                          : option.value}
                      </Box>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={defaultOption.value}>
                    <Box display="flex" alignItems="center">
                      {defaultOption.icon && (
                        <img
                          src={defaultOption.icon}
                          alt={defaultOption.label}
                          style={{ marginRight: "10px", height: "30px" }}
                        />
                      )}
                      {menuOpen && defaultOption.label
                        ? `${defaultOption.value} - ${defaultOption.label}`
                        : defaultOption.value} // Show only value if label is undefined
                    </Box>
                  </MenuItem>
                )}
              </Select>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default FormattedCurrencyField;
