// ============================|| FORMAT MULTILINE ADDRESS ||============================ //
export const formatAddress = (info) => {
  const { address, street2, city, country, postalCode, regionCode } =
  info || {};
  const parts = [city, regionCode, postalCode, country];

  const filteredParts = parts.filter(
    (part) => part !== "" && part !== undefined,
  );

  const lines = [];
  if (address !== undefined && address !== "") {
    lines.push(`${address},`);
  }
  if (street2 !== undefined && street2 !== "") {
    lines.push(`${street2},`);
  }

  // Loop through the remaining parts and construct lines
  for (let i = 0; i < filteredParts.length; i += 2) {
    // Combine pairs of elements, separated by a comma if the second element is present
    const line =
      filteredParts[i] +
      (filteredParts[i + 1] ? `, ${filteredParts[i + 1]}` : "");
    lines.push(line);
  }

  return lines.join("\n");
};