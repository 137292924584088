import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import OtpInput from "react18-input-otp";
import { renderBankDetails } from "../../../store/renderBankDetails";
import { getFormattedCurrencyAmountWithName } from "../../../store/getFormattedCurrencyAmount";
import useEntityDetails from "../../../store/hooks/useEntityDetails";

const styles = {
  card: {
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    backgroundColor: "white"
  },
  header: {
    backgroundColor: "#f2f2f2",
    padding: "18px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px"
  },
  table: {
    border: "none"
  },
  tableCell: {
    fontSize: "18px",
    fontWeight: "normal"
  }
};

const DomesticPaymentConfirmationModal = ({
                                            open,
                                            onConfirm,
                                            onCancel,
                                            requestData,
                                            entityDetails,
                                            setFieldValue
                                          }) => {
  const { entityDetails: fetchedEntityDetails, error, loading } = useEntityDetails(entityDetails?.value);

  const renderAmount = getFormattedCurrencyAmountWithName(requestData.amount, requestData.currency);

  const handleConfirmClick = async () => {
    await onConfirm();
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent>
        <Paper style={styles.card}>
          <div style={styles.header}>
            <Typography variant="h2">Transaction Confirmation</Typography>
          </div>
          <TableContainer component={Paper} style={styles.table}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...styles.tableCell }}>
                    {entityDetails.label} receives: <b>{renderAmount}</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ ...styles.tableCell }}>
                    {sessionStorage.getItem("clientName")} sends: <b>{renderAmount}</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ ...styles.tableCell }}>
                    <Typography variant="h3" gutterBottom>
                      Beneficiary Details:
                    </Typography>
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : fetchedEntityDetails?.details ? (
                      renderBankDetails(fetchedEntityDetails.details, entityDetails.label)
                    ) : (
                      <Typography variant="body2" color="error">
                        {error ? "Failed to load beneficiary details." : "No details available."}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ ...styles.tableCell }}>
                    Enter 6-digit authentication code:
                    <OtpInput
                      value={requestData.totp}
                      onChange={(totpNumber) => setFieldValue("totp", totpNumber)}
                      numInputs={6}
                      containerStyle={{ justifyContent: "space-between" }}
                      inputStyle={{
                        width: "100%",
                        margin: "8px",
                        padding: "10px",
                        border: `1px solid `,
                        borderRadius: 4,
                        ":hover": {
                          borderColor: "#000"
                        }
                      }}
                      focusStyle={{
                        outline: "none",
                        border: `2px solid #000`
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          variant={"contained"}
          sx={{ backgroundColor: "#FF0000", color: "#fff" }}
          size={"large"}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmClick}
          sx={{ backgroundColor: "#008000", color: "#fff" }}
          variant="contained"
          size="large"
          disabled={requestData.totp?.length !== 6}
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DomesticPaymentConfirmationModal;
