import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { Form } from "formik";
import PropTypes from "prop-types";
import CustomFormField from "../../ui-component/form-fields/CustomFormField";
import CustomCurrencyValueInput from "../../ui-component/form-fields/CustomCurrencyValueInput";
import CustomDropdown from "../../ui-component/form-fields/CustomDropdown";
import { IconHelp } from "@tabler/icons-react";
import { getCurrencyFlag } from "../../common/currencyFlagHelper";
import { getLedgerDisplayText } from "../../store/getLedgerDisplay";
import useLedgers from "../../store/hooks/useLedgerDetails";
import { getAccounts } from "../../store/getAccounts";
import DomesticPaymentConfirmationModal from "../../ui-component/extended/modal/DomesticPaymentConfirmationModel";

const DomesticCreditForm = ({
                              values,
                              handleChange,
                              handleBlur,
                              touched,
                              errors,
                              validateForm,
                              setTouched,
                              handleSubmit,
                              entityDetails,
                              insufficientFundsError,
                              setFieldValue,
                              handleBack,
                              transactionType

                            }) => {

  const { activeLedgers } = useLedgers();

  const [usdLedgers, setUsdLedgers] = useState([]);
  const [selectedLedger, setSelectedLedger] = useState(null);
  const [confirmationData, setConfirmationData] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const entityName = entityDetails.label;

  const handleConfirmationOpen = (values) => {
    setConfirmationData(values);
    setConfirmationModalOpen(true);
    setConfirmationData(values);
  };

  const handleConfirmationClose = () => {
    setConfirmationModalOpen(false);
  };

  // Filter USD ledgers and set defaults for ledgerId and account
  useEffect(() => {
    if (!activeLedgers || !Array.isArray(activeLedgers)) return;
    const usdLedgers = activeLedgers.filter((ledger) => ledger.currency === "USD");
    setUsdLedgers(usdLedgers);

    if (usdLedgers.length === 1) {
      setSelectedLedger(usdLedgers[0]);
      setFieldValue("ledgerId", usdLedgers[0].ledgerId);
      setFieldValue("account", usdLedgers[0].accounts?.[0] || "");
      return;
    }

    const ledger = usdLedgers.find((ledger) => ledger.ledgerId === values.ledgerId) || null;
    setSelectedLedger(ledger);
  }, [activeLedgers]);

  return (
    <Form style={{ width: "100%", maxWidth: "800px" }}>
      <Grid container spacing={1}>
        {transactionType === "ach" &&
          <Grid item xs={12} my={2}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Select Delivery Option:
            </Typography>
            <ToggleButtonGroup
              value={values.when}
              exclusive
              onChange={(event, newValue) => {
                if (newValue) {
                  setFieldValue("when", newValue);
                }
              }}
              aria-label="Delivery option"
              color="primary"
            >
              <ToggleButton value="standard" aria-label="Standard">
                Standard
              </ToggleButton>
              <ToggleButton value="sameday" aria-label="Same Day">
                Same Day
              </ToggleButton>
              <ToggleButton value="immediate" aria-label="Instant">
                Instant
              </ToggleButton>
            </ToggleButtonGroup>
            {touched.when && errors.when && (
              <Typography variant="caption" color="error">
                {errors.when}
              </Typography>
            )}
          </Grid>
        }
        <Grid item xs={8}>
          <CustomFormField
            name="reference"
            label="Internal Tracking Reference"
            value={values.reference}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            endAdornment={
              <Tooltip title="You can use this reference if you have questions about this transaction with your bank.">
                <IconButton>
                  <IconHelp />
                </IconButton>
              </Tooltip>
            }
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <CustomFormField
            name="entityName"
            label="Beneficiary Name"
            value={entityName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            disabled={true}
          />
        </Grid>

        {usdLedgers?.length > 1 &&
          <Grid item xs={12} sm={6}>
            <CustomDropdown
              name="ledgerId"
              label="Select Source Account"
              value={values.ledgerId}
              options={usdLedgers.map((ledger) => ({
                value: ledger.ledgerId,
                label: getLedgerDisplayText(ledger)
              }))}
              handleChange={(e) => {
                const newLedger = usdLedgers.find(
                  (ledger) => ledger.ledgerId === e.target.value
                );
                setSelectedLedger(newLedger);
                setFieldValue("ledgerId", e.target.value);
                setFieldValue("account", newLedger?.accounts?.[0] || "");
              }}
              errors={errors}
              handleBlur={handleBlur}
              touched={touched}
            />
          </Grid>
        }


        {selectedLedger?.accounts?.length > 0 && (
          <Grid item xs={12} sm={6}>
            <CustomDropdown
              name="account"
              label="Select Account"
              value={values.account}
              options={getAccounts(selectedLedger.accounts).map((acc) => ({
                value: acc,
                label: acc
              }))}
              handleChange={handleChange}
              errors={errors}
              handleBlur={handleBlur}
              touched={touched}
              disabled={selectedLedger?.accounts?.length === 1}
            />
          </Grid>
        )}

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <CustomCurrencyValueInput
              name="amount"
              label="Amount"
              value={values.amount}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomDropdown
              name="currency"
              label="Currency"
              value={values.currency}
              errors={errors}
              handleBlur={handleBlur}
              touched={touched}
              disabled={true}
              options={[
                {
                  value: "USD",
                  label: "USD",
                  icon: getCurrencyFlag("USD")
                }
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <CustomFormField
            name="description"
            label="Purpose"
            value={values.description}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            maxLength={35}
            validateChars={true}
          />
        </Grid>

        <Grid item xs={8}>
          <CustomFormField
            name="addenda"
            label="Note To Recipient (Optional)"
            value={values.addenda}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            maxLength={1024}
            validateChars={true}
            endAdornment={
              <Tooltip title="This is a message sent with the transaction.">
                <IconButton>
                  <IconHelp />
                </IconButton>
              </Tooltip>
            }
          />
        </Grid>

        <Grid item xs={8}>
          <CustomFormField
            name="transactionNote"
            label="Internal Transaction Note (Optional)"
            value={values.transactionNote}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            maxLength={1024}
            validateChars={true}
            endAdornment={
              <Tooltip title="Add any internal notes or references for this transaction.">
                <IconButton>
                  <IconHelp />
                </IconButton>
              </Tooltip>
            }
          />
        </Grid>

        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item xs={6}>
            {!insufficientFundsError && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={async () => {
                  const errors = await validateForm();
                  if (Object.keys(errors).length === 0) {
                    handleConfirmationOpen(values);
                  } else {
                    setTouched({
                      reference: true,
                      amount: true,
                      description: true
                    });
                  }
                }}
              >
                Review Transaction
              </Button>
            )}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        {confirmationData && (
          <DomesticPaymentConfirmationModal
            open={confirmationModalOpen}
            onCancel={handleConfirmationClose}
            onConfirm={async () => {
              await handleSubmit(values);
              handleConfirmationClose();
            }}
            requestData={values}
            displayedCurrency={"USD"}
            entityDetails={entityDetails}
            setFieldValue={setFieldValue}
          />
        )}
      </Grid>
    </Form>


  );
};

DomesticCreditForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validateForm: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  insufficientFundsError: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired
};

export default DomesticCreditForm;
